import React from 'react';
import {
    Container,
    Grid,
    Image,
    Input,
    Label,
    Form,
    Divider,
    Header,
    Icon
} from 'semantic-ui-react'
import 'semantic-ui-css/semantic.min.css'
import './App.css';

class App extends React.Component {
    constructor(props){
        super(props);
        this.state = {
        }
    }

    render(){
        return (
            <Container style={{padding: '10px'}}>
                <center>
                    <Image src='logo128.png'  verticalAlign='middle' />
                    <Divider horizontal>
                        <Header as='h4'>
                            enter domain name to scan
                        </Header>
                    </Divider>
                    <Form style={{maxWidth: '800px'}}>
                        <Input label={{content: 'https://', color: 'cmain'}} placeholder='mysite.com' action={{ icon: 'search', color:'cmain' }} fluid/>
                    </Form>
                </center>
                <Divider horizontal>
                    <Header as='h4'>
                        scan queue
                    </Header>
                </Divider>
            </Container>
        );
    }
}


export default App;
